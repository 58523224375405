// core/_cards.scss
.card {
  border: 1px solid $grey-400;

  .card-header-primary .card-icon,
  .card-header-primary .card-text,
    /*  .card-header-primary:not(.card-header-icon):not(.card-header-text), */
  &.bg-primary,
  &.card-rotate.bg-primary .front,
  &.card-rotate.bg-primary .back {
    background: linear-gradient(60deg, $primary-amadeus-blue, $primary-amadeus-blue);
  }

  [class*="card-header-"] {
    .card-title {
      font-size: 1.5rem;
    }

    .card-title,
    .card-title + .card-category {
      color: $font-color;
    }

    &:not(.card-header-icon):not(.card-header-text):not(.card-header-image) {
      margin-top: 0px;
    }
  }

  .card-header-primary:not(.card-header-icon):not(.card-header-text) {
    background: white;
  }

  .card-header-rose .card-icon,
  .card-header-rose .card-text,
  .card-header-rose:not(.card-header-icon):not(.card-header-text),
  &.bg-rose,
  &.card-rotate.bg-rose .front,
  &.card-rotate.bg-rose .back {
    background: linear-gradient(60deg, $accent-cherry, $accent-cherry);
  }
}

// core/_misc.scss
body{
  background-color: $body-bg;
}

// core/_navbar.scss

.navbar .navbar-brand {
  font-size: 1.4rem;
  color: $primary-light-blue;
  img {
    height: 30px;
    margin-right: 1rem;
  }
  .icon-amadeus {
    color: $primary-amadeus-blue;
    margin-right: 0.25rem;
  }
}

// core/_sidebar-and-mainpanel.scss

.sidebar {
  border-right: 1px solid $grey-400;
}

// core/_responsive.scss

@media (max-width: 991px) {
  .sidebar {
    .nav li a,.nav li a:hover {
      color: white;
      margin-top: 0;
    }
    &::before{
      background: linear-gradient(60deg, $primary-light-blue, $primary-amadeus-blue);
    }
    &[data-color="purple"] li:hover {
      & > a {
        background: $primary-pale-blue;
      }
    }
  }
}
@media (min-width: 992px) {
  .sidebar {
    top: $header-height;
    .logo {
      display: none;
    }
  }
}




