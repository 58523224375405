@import "./variables.scss";

.error-display {
  background: $primary-white;
  border: 5px solid;
  border-color: $accent-red;
  margin-top: 75px !important;
}

.user-container {
  display: flex;
  flex-direction: column;
  margin: auto;
}
