// Bootstrap brand color customization


/*     brand Colors              */

$font-family-sans-serif: "Source Sans Pro", Calibri, sans-serif;
$body-bg: #ffffff;

$primary-amadeus-blue: #005eb8;
$primary-light-blue: #00a9e0;
$primary-pale-blue: #9bcaeb;
$primary-sky-blue: #e7f2fa;
$primary-light-gray: #f5f5f5;
$primary-white: #ffffff;
$primary-white-smoke: #efefef;

$accent-purple: #6f2b8d;
$accent-cherry: #ce0058;
$accent-deep-orange: #e95326;
$accent-orange: #f7a827;
$accent-yellow: #feeb3d;
$accent-red: #c60000;
$accent-green: #00a34e;

$enable-shadows: false;

$brand-primary:              $primary-amadeus-blue !default;
$brand-info:                 $primary-light-blue !default;
$brand-success:              $accent-green !default;
$brand-warning:              $accent-orange !default;
$brand-danger:               $accent-red !default;
$brand-rose:                 $accent-cherry !default;
$brand-inverse:              $black-color !default;

$header-height: 75px;
